import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import { useEffect, useState } from "react";

function Scanner() {
    const [scanResult, setScanResult] = useState(null);
    const [scanAgain, setScanAgain] = useState(false);

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', 
            {
                qrbox: { width: 300, height: 150, },
                fps: 5,
                aspectRatio: 1.0,
                rememberLastUsedCamera: true,
                supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
            }
        );
        
        if (!scanAgain) {
          scanner.render(success, error);
        }

        function success(result) {
            scanner.clear();
            setScanResult(result);
            setScanAgain(true);
        }

        function error(err) {
            console.warn(err);
        }
    },[scanAgain]);

    return (
        <div>
            { scanResult && !scanAgain
            ? <div>
                Success: <a href={scanResult}>{scanResult}</a>
                <button onClick={() => { setScanResult(null); setScanAgain(false); }}>Scan Again</button>
              </div>
            : <div id="reader"></div>
            }
        </div>
    )
}

export default Scanner;