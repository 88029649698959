import Scanner from './components/Scanner.js'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <text>opal v0.3</text>
        <Scanner/>

      </header>
    </div>
  );
}

export default App;
